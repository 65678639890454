// Vue modules
import { reactive, computed } from "vue";
// State
const state = reactive({
  loading: false,
  error: {
    status: "",
    headers: "",
    data: "",
    show: ""
  }
});
// Actions
const actions = {
  setLoading(status) {
    state.loading = status
  }
  
}

// Mutations
function setError(error) {
  console.log(error);
  // state.error.status = error.response.status;
  // state.error.headers = error.response.headers;
  // state.error.data = error.response.data;
  // state.error.show = true;

  // setTimeout(() => state.error.show = false, 3000);
}


function setNestedObject(obj, path, value) {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, '');           // strip a leading dot

  const pList = path.split('.');

  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
}

export {
  state,
  actions,
  setError,
  setNestedObject
}