const {
  createRouter,
  createMemoryHistory,
  createWebHistory,
} = require('vue-router');

const isServer = typeof window === 'undefined';

let history = isServer ? createMemoryHistory() : createWebHistory();

import layout from '@/components/layout/index.vue'

const routes = [{
  path: '/',
  component: layout,
  meta: {},
  children: [{
    path: '',
    component: () => import(/* webpackChunkName: 'Home' */ '@/components/pages/Home.vue'),
    name: 'Home',
    meta: { title: 'Home' }
  },{
    path: 'foundation',
    component: () => import(/* webpackChunkName: 'Foundation' */ '@/components/pages/WIP.vue'),
    meta: { theme: 'Black' },
  },{
    path: 'governance',
    component: () => import(/* webpackChunkName: 'Governance' */ '@/components/pages/WIP.vue'),
    meta: { theme: 'Black' },
  },{
    path: 'initiatives',
    meta: { theme: 'Black' },
    component: () => import(/* webpackChunkName: 'Initiatives' */ '@/components/pages/WIP.vue'),
  },{
    path: 'buy',
    name: 'Buy OZDT',
    meta: { theme: 'Black' },
    component: () => import(/* webpackChunkName: 'BuyOZDT' */ '@/components/pages/Buy.vue'),
  },{
    path: 'contacts',
    component: () => import(/* webpackChunkName: 'Contacts' */ '@/components/pages/Contacts.vue'),
    meta: { title: 'Contacts' }
  }]
},{
  path: '/dao',
  component: layout,
  meta: { authorize: [] },
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: 'Dashboard' */ '@/components/pages/admin/Dashboard.vue'),
    meta: { title: 'Dashboard'}
  },{
    path: 'initiatives',
    name: 'InitiativesDao',
    meta: { title: 'Wallet'},
    children: [{
      path: '',
      name: 'Initiatives',
      component: () => import(/* webpackChunkName: 'Initiatives' */ '@/components/pages/admin/Initiatives.vue'),
      meta: { title: 'Digital Dao', }
    },{
      path: 'digitaldao',
      name: 'DigitalDao',
      component: () => import(/* webpackChunkName: 'Home' */ '@/components/pages/admin/digitaldao.vue'),
      meta: { title: 'Digital Dao', }
    },{
      path: 'add',
      name: 'AddInitiatives',
      component: () => import(/* webpackChunkName: 'AddInitiative' */  '@/components/pages/admin/digitaldao.vue'),
    },{
      path: '/:url',
      name: 'EditInitiatives',
      component: () => import(/* webpackChunkName: 'EditInitiative' */ '@/components/pages/admin/digitaldao.vue'),
    }]
  },{
    path: 'members',
    name: 'Members',
    component: () => import(/* webpackChunkName: 'Members' */ '@/components/pages/admin/Members.vue'),
    meta: { title: 'members'}
  },{
    path: 'wallet',
    name: 'Wallet',
    component: () => import(/* webpackChunkName: 'Wallet' */ '@/components/pages/admin/Wallet.vue'),
    meta: { title: 'Wallet'}
  },{
    path: 'account',
    name: 'Account',
    component: () => import(/* webpackChunkName: 'Account' */ '@/components/pages/admin/Account.vue'),
    meta: { title: 'Account',}
  }]
},{
  path: '/:pathMatch(.*)*',
  name: '404',
  component: () => import(/* webpackChunkName: '404' */ '@/components/pages/404.vue'),
  meta: { title: 'Page is missing' }
}];

const scrollBehavior = (to, from, savedPosition) => {

  const position = {}
  // new navigation.
  // scroll to anchor by returning the selector
  if (to.hash) {
   position.selector = to.hash
  }
  // check if any matched route config has meta that requires scrolling to top
  if (to.matched.some(m => m.meta.scrollToTop)) {
   // cords will be used if no selector is provided,
   // or if the selector didn't match any element.
   position.x = 0
   position.y = 0
  }
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  return position
}

export function createAppRouter() {
  return createRouter({ routes, history });
}

