<template>
	<!-- :class="{'t-dark':route.meta.theme !== 'Black', 't-white':route.meta.theme === 'Black'}"   -->
	<header 
	:class="{'t-dark':route.meta.theme !== 'Black', 't-white':route.meta.theme === 'Black'}"
	ref="headerRef" 
	class="z-index-3 page-header">
		<router-link class="header-logo" to="/">
			<div  class="flex-center flex">
				<Logo />
				
				<div  >
					<span class="display-block t-bold t-lh-1 h5">OZ DAO</span>
					<span class='display-block uppercase t-lh-1 t-medium t-transp p-medium'>Digital Underground</span>
				</div>
			</div>
		</router-link>

		<nav v-if="!Store.auth.state.user.loggedIn" class=" flex-center flex">
			<router-link class=" mn-r-medium t-medium desktop-only" to="/foundation">Foundation</router-link>
			<router-link class="mn-r-medium t-medium desktop-only" to="/governance">Governance</router-link>
			<router-link class="mn-r-medium t-medium desktop-only" to="/initiatives">Initiatives</router-link>
			<router-link class="mn-r-medium t-medium desktop-only" to="/contacts">Contacts</router-link>
			
			<!-- <button @click="Store.auth.toggleSignInPopup()" class="mn-r-small t-white bg-dark button-small button ">ENTER 🗝️</button> -->
			<button @click="router.push({name: 'Buy OZDT'})" class="mn-r-small button-small bg-main button desktop-only">BUY OZ 💵</button>
		</nav>

		<nav v-if="Store.auth.state.user.loggedIn" class=" flex-center flex">
			<router-link class="mn-r-medium t-medium" to="/dao/dashboard">Dashboard</router-link>
			<router-link class="mn-r-medium t-medium" to="/dao/initiatives">Initiatives</router-link>
			<router-link class="mn-r-medium t-medium" to="/dao/members">Members</router-link>
			<router-link class="mn-r-medium t-medium" to="/dao/wallet">Wallet</router-link>
			<router-link class="mn-r-medium t-medium" to="/dao/account">Account</router-link>
			<button @click="exit()" class="t-white bg-dark button-small button">EXIT 🗝️</button>
		</nav>

	</header>
</template>

<script setup scoped>
/////////////////////////////
// COMPONENT DEPENDENCIES
/////////////////////////////
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
// State
import * as Store from '@/store';
// GSAP DEPENDENCIES
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// USED COMPONENTS
import Logo from '@/components/partials/Logo.vue';
/////////////////////////////
// HELPERS
/////////////////////////////
function checkMaxWidth (width) {    
  return window.matchMedia('(max-width: ' + width + ')').matches;
}
function checkClass(cssClass, element) {    
  return element.classList.contains(cssClass)
}
/////////////////////////////
// CREATED
/////////////////////////////
gsap.registerPlugin(ScrollTrigger);

const headerRef = ref(null)

const router = useRouter();
const route = useRoute();

function exit () {
	router.push({ name: 'Home'})
	Store.auth.logout()
}

/////////////////////////////
// MOUNTED
/////////////////////////////
onMounted(()=> {
	var tl = gsap.timeline({
    // defaults: {duration: 1, ease: "power2.inOut"},
    scrollTrigger: {
      start: 'top -150',
      end: 9999,
      onToggle: function() {
        var first = headerRef.value.getBoundingClientRect();
        headerRef.value.classList.toggle('scrolled');
        var last = headerRef.value.getBoundingClientRect();
        var invert = first.top - last.top;
        gsap.fromTo('.page-header', {
          y: invert,
          force3D:false,
          padding: checkMaxWidth('640px') ? "2rem" : (checkClass('scrolled', headerRef.value) ? '2.5rem 2rem' : '1.25rem'),
        }, {
          y: 0,

          padding: checkMaxWidth('640px') ? "2rem" : (checkClass('scrolled', headerRef.value) ? '1.25rem' : '2.5rem 2rem'),
          ease: "cubic-bezier(.08,.75,.77,1.03)",
        })
      },
    }
  });
	// LOGO INTRO
  tl.to('.page-header', {
    autoAlpha: 1,
    y: 0,
    // scale: 0,
    duration: 1,
  }, 0)
  tl.play();
})
</script>

<style lang="scss">
.page-header {
	display: flex;
	position: absolute;

	flex-wrap: wrap;

	width: 100%;
	height: auto;

	opacity: 0;

	background: transparent;

	padding: 2rem;

	justify-content: space-between;
	align-items: center;
	
	 transform: translateY(-160px);
	
	.header-logo svg { height: 3rem; width: 3rem; } 
	.lines-intro { opacity: 0; } 
}

header.scrolled {
	position: fixed !important; 
	background: white;

	.white { fill: white;}
	.main { fill: #00ff88; } 

	.lines-intro-header {
		opacity: 1;
	}
} 

@media screen and (max-width: 1024px) {
	.page-header {
		padding: 2rem;

		.header-logo {
			svg { height: 2.5rem; width: 2.5rem; } 
		}

		.lines-intro {
			opacity: 1;
		}
	}

	#headerNav {
		a { font-size: 1.375rem; }
	}
}

@media screen and (max-width: 321px) {
	.page-header {
		#headerNav {
			a { font-size: 1.75rem; }
		}
	}
}
</style>
