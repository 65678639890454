const isProd = process.env.NODE_ENV === 'production';
// Dependencies
import axios from 'axios';

const clientUrl = process.env.API_URL;

const $axios = axios.create({baseURL: clientUrl})
// Vue modules
import { reactive, ref, computed, toRefs, watch  } from "vue";
// Globals
import { setError }   from '@/store/modules/global'
// State
const state = reactive({
  all: [],
  current: {
    id: "",
    url: "",
    status: "",
    name: "",
    tags: [],
    author: "",
    authorId: '',
    date: "",
    views: 0,
    content: [],
  }
});

async function fetch(options = {}) {
  console.log(options)
  // Handle fetching a specific post by ID
  if (options.url) {
    options.params = { url: options.url };
  }

  // Handle fetching posts by category
  if (options.category) {
    options.params = options.params || {};
    options.params.category = options.category;
  }

  // Handle pagination
  options.params = options.params || {};
  options.params.skip = options.skip || 0;
  options.params.limit = options.limit || 10;

  if (options.authorId) {
    options.params.authorId = options.authorId;
  }

  if (options.website) {
    options.params.website = options.website;
  }
  
  try {
    console.log('Request params:' + options.params)
    const response = await $axios.get('/blog/fetch', { params: options.params });
    
     if (options.url) {
      set(response.data, 'current')
    } else {
      set(response.data, 'all')
    }
    return Promise.resolve(response.data);
  } catch (error) {
    setError(error)
    return Promise.reject(error);
  }
}
async function create(blogpost) {
  return $axios.post('/blog/create', blogpost).then(
    response => {
      set(response.data, 'current')
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}

async function update(blogpost) {
  return $axios.post('/blog/update', blogpost).then(
    response => {
      set(response.data, 'current')
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}

function set(blogpost,property) { 
  state[property] = blogpost
}

function clean () {
  state.current = {
    id: "",
    url: "",
    status: "",
    name: "",
    tags: [],
    author: "",
    authorId: '',
    date: "",
    views: 0,
    content: []
  }
}


 // Update existing categoryZzzz
async function fetchProjects() {
  return await $axios.get(`/projects`).then(response => {
    setProjects(response.data)
    return Promise.resolve(response.data);
  });
}


// Actions


function blockAdd (block) {
  const blockNew = {
    order: state.project.content.length + 1,
    type: block.type,
    data: block.data,
    class: block.class,
    content: block.content
  }
  state.project.content.push(blockNew)
}

// Mutations
function setProjects(projects) { 
  state.all = projects
}

// Gettes
const getProject = computed(() => state.project)

const history = []
history.push(state) // push initial state

watch(state, (newState, oldState) => {
  history.push(newState)
})

export {
  state,
  fetch,
  create,
  update,
  set,
  clean,
  blockAdd
}