  import { gsap } from "gsap/dist/gsap";
  
export default function initCursor() {
    
    // ANY ELEMENT INTRO GENERIC
   var cursor = document.querySelector(".cursor");
    var follower = document.querySelector(".cursor-follower");
    var page = document.getElementById('app');
    var button = document.querySelector('.button-play');
    var buttonText = document.querySelector('.arrow-right');
    var followArea = document.querySelectorAll('.play-cursror');

    var posX = 0,
    posY = 0;

    var mouseX = 0,
    mouseY = 0;


    
    page.addEventListener("mousemove", function(e) {
      mouseX = e.clientX;
      mouseY = e.clientY;
    });

    page.addEventListener("mouseenter", function(e) {
      cursor.classList.add("visible");
      follower.classList.add("visible");

      gsap.to({}, 0.016, {
        repeat: -1,
        onRepeat: function() {
          posX += (mouseX - posX) / 9;
          posY += (mouseY - posY) / 9;

          gsap.to(follower, {
            left: mouseX - 12,
            top: mouseY - 12
          });

          gsap.set(cursor, {
            left: mouseX,
            top: mouseY
          });
        }
      });
    }); 

    var linksHover = document.querySelectorAll('.cursor-hover');

    linksHover.forEach(function(el) {
      el.addEventListener('mouseover', () => {
        cursor.classList.add("active");
        follower.classList.add("active");
      })

      el.addEventListener('mouseout', () => {
        cursor.classList.remove("active");
        follower.classList.remove("active");
      })

      el.addEventListener('mousedown', () => {
        cursor.classList.add("normal");
        follower.classList.add("normal");
      })

      el.addEventListener('mouseup', () => {
  

        function anim() {
                cursor.classList.remove("normal");
          follower.classList.remove("normal");
        }

        setTimeout(anim, 300);
      })



    })

    
      var moveCircle = (e)  => {
        gsap.to(button, 0.25, {
          css: {
            left: e.pageX,
            top: e.pageY

          }
        });
      }


      // // Event Listeners
      // // ************

      followArea.forEach(function(el) {
        // Enter
        el.addEventListener('mouseover', (e) => {
          // gsap.set(button, {
          //   css: {
          //     left: e.pageX,
          //     top: e.pageY,

          //   },
            
           
          // });
          gsap.to(button, 0.25, {
            scale: 1,
            autoAlpha: 1

          });

          page.addEventListener('mousemove', moveCircle);

          cursor.classList.remove("visible");
          follower.classList.remove("visible");
        });
        // Leave
        el.addEventListener('mouseout', () => {
          gsap.to(button, 0.25, {
            scale: 0.5,
            autoAlpha: 0,
          });


          cursor.classList.add("visible");
          follower.classList.add("visible");
        });
        // Click
        el.addEventListener('mousedown', () => {

          gsap.to(button, 0.5, {
            css: { transform: `translate(-50%, -50%) scale(0.75)` }
          });

          gsap.to(buttonText, 0.25, {
            css: { opacity: 1  }
          });
        });
        // After click
        el.addEventListener('mouseup', () => {
          gsap.to(button, 1, {
            css: { background: `#00ff88` }
          });

          gsap.to(button, 0.5, {
            css: { transform: `translate(-50%, -50%) scale(1)` }
          });

          gsap.to(buttonText, 0.25, {
            css: {
              opacity: 1
            }
          });
        });

      })
  } 