import Vue from "vue";

import { createI18n } from 'vue-i18n'

let messages = {
  en: {
    name: "🌎 EN",
  }, 
  ru: {
    name: "🇷🇺 RU",
  }
}

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: "en",
    legacy: false,
    globalInjection: true,
    messages: messages,
})

export default i18n;

