<template>
  <router-view v-slot="{ Component, route }">
      <component :is="Component" />
  </router-view>
</template>

<script setup>
  import { useMeta } from 'vue-meta'

  useMeta({
    title: 'OZ DAO – Digital Scientists Inspired by The Power of WWW',
    description: 'OZ DAO is a collective of crypto-anarchists and entrepreneurs who support the principles of free trade and privacy, governed entirely by its individual members who collectively make critical decisions about the future of the project.',
    htmlAttrs: { lang: 'en', amp: true }
  })
</script>

<style lang="scss">
  @import "./scss/theme.scss";
</style>
