const isProd = process.env.NODE_ENV === 'production';
// Dependencies
import axios from 'axios';

const clientUrl = process.env.API_URL;

const $axios = axios.create({baseURL: clientUrl})
// Vue modules
import { reactive, ref, computed, toRefs, watch  } from "vue";
// Globals
import { setError }   from '@/store/modules/global'
// State
const state = reactive({
  all: [], 
  roles: [],
  current: { 
    phone: '',
    name: '',
    surname: '',
    birthday: '',
    city: '',
    adress: '',
    roles: '',
    orders: [],
    roles: []
  }
});

async function fetchUser(user) {

  console.log(state)
  console.log(user)
  return await $axios.get('/users/' + user).then(
    response => {
      state.current.phone = response.data.phone;
      state.current.name = response.data.name;
      state.current.surname = response.data.surname;
      state.current.birthday = response.data.birthday;
      state.current.city = response.data.city;
      state.current.adress = response.data.adress;
      state.current.orders = response.data.orders;
      state.current.balance = response.data.balance;
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}  

async function fetchUsers(phone) {
  return await $axios.get('/users').then(
    response => {
      state.all = response.data
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}  


// fetchRoles({ commit }) {
//       $axios.get(`/users/roles`).then(
//         roles => {
//           commit('setRoles', roles.data)
//           return Promise.resolve(users.data);
//         },
//         error => {
//           commit('setError', error)
//           return Promise.reject(error);
//         }
//       );
//     },
//     fetchUsers({ commit }) {
//       $axios.get(`/users`).then(
//         users => {
//           commit('setUsers', users.data)
//           return Promise.resolve(users.data);
//         },
//         error => {
//           commit('setError', error)
//           return Promise.reject(error);
//         }
//       );
//     },
    // Fetch order by ID

    // updateUser({ state, commit }, user) {
    //   return $axios.post('/users/' + user.phone, user).then(
    //     response => {
    //       console.log(response)
    //       // commit('setUser', response.data)
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       console.log(error)
    //       commit('setCodeError', error, { root: true })
    //       return Promise.reject(error);
    //     }
    //   );
    // },

const history = []
history.push(state) // push initial state

watch(state, (newState, oldState) => {
  history.push(newState)
})

export {
  state,
  fetchUser,
  fetchUsers 
}