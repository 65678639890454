<template>
   <footer id="footer" class="t-uppercase flex-center flex t-black" data-scroll-section>
    <p class="uppercase t-medium w-100 ">
    	2022 
      <span class="t-main copyleft">❤</span> 
      Wizard of OZ. Builded with love in Digital World.
    </p>
  </footer>
</template>

<script setup>
</script>

<style lang="scss">
  #footer {
    padding-top: 2rem;
    padding-bottom: 2rem;

  }
</style>