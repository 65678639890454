import { createSSRApp } from "vue";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import { createWebHistory } from "vue-router";


import App from "./App.vue";
import { _createApp } from './app';

const { app, router, Store, i18n } = _createApp();


const start = async () => {
;
  const metaManager = createMetaManager();
  app.use(metaManager);
  app.use(metaPlugin);

  const userState = JSON.parse(
    document.querySelector("[data-user-state]").textContent
  );

  if (userState) {
    Store.auth.state.user = userState
    Store.auth.state.user.loggedIn = true
  }

  await router.isReady();

  app.mount("#app");
};

start();
