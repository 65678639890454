const isProd = process.env.NODE_ENV === 'production';
// Dependencies
import Cookies from 'js-cookie'

import axios from 'axios';

const clientUrl = process.env.API_URL;

const $axios = axios.create({baseURL: clientUrl})
// Vue modules
import { reactive, ref, computed, toRefs, watch  } from "vue";
// Globals
import { setError }   from '@/store/modules/global'
import * as Users     from '@/store/modules/users'
// State
const state = reactive({
  status: { 
    signin: {
      isOpen: false,
      signup: false
    },
    signup: {
      code: {
        isSended: false,
        value: '',
        isValid: false 
      } 
    },
  }, 
  user: {
    id: null,
    username: '',
    avatar: '',
    phone: '',
    password: '',
    repeatpassword: '',
    code: '',
    loggedIn: false, 
    roles: []
  },
});

async function login (user) {
  return await $axios
    .post('/api/auth/signin', {
      phone: user.phone,
      password: user.password
    })
    .then(
      response => {
        if (response.data.accessToken) {
          Cookies.set('user', JSON.stringify(response.data));
        }

        state.user.loggedIn = true;
        state.user.phone = response.data.phone;
        state.user.accessToken = response.data.accessToken;
        state.user.id = response.data.id;
        state.user.username = response.data.username;
        state.user.avatar = response.data.avatar;
        state.user.roles = response.data.roles;


        Users.state.current.phone = response.data.phone;

        return  Promise.resolve(response.data);
      },
      error => {
        state.user.loggedIn = false;
        state.user.phone = null;
        state.user.accessToken = null;
        state.user.id = null;

        setError(error)
        console.log('Login falied')
        return Promise.reject(error);
      }
    );
}

async function sendCode (user) {
  return await $axios
    .post('/api/auth/sendcode', {
      phone: user.phone
    })
    .then(
      response => {
        state.status.signup.code.isSended = true;
        state.status.signup.code.value = response.data;

        return  Promise.resolve(response.data);
      },
      error => {
        setError(error)
        return Promise.reject(error);
      }
    );
}

function validCode () {
  const rightcode = state.status.signup.code.value.code
  const usercode =  state.user.code

  if ( rightcode == usercode ) {
   state.status.signup.code.isValid = true;
  } else {
   setError({ response: {status: "Неверный код", data: { message: "Вы ввели неверный код. Попробуйте еще раз."}}})
  }
}

async function signup (user) {
  const pass =  state.user.password;
  const repeatpass =  state.user.repeatpassword

  if ( pass === repeatpass ) {
    return await $axios
      .post('/api/auth/signup', {
        phone: user.phone,
        password: user.password
      })
      .then(
        response => {
          if (response.data.accessToken) {
            Cookies.set('user', JSON.stringify(response.data));
          }

          // store.dispatch("openSignInPopup");
          // store.dispatch('resetSignIn')
          // router.push({ name: 'Account'})
          console.log('success');
          
          state.user.loggedIn = true;
          // state.user = JSON.parse(Cookies.get('user'));

          return  Promise.resolve(response.data);
        },
        error => {
          setError(error)
          console.log('Login falied')
          return Promise.reject(error);
        }
      );
  } else {
    setError({ response: {status: "Пароли не совпадают", data: { message: "Введенные вами пароли не совпадают. Исправьте ошибки и попробуйте еще раз."}}})
  }  
}

function logout() {
  Cookies.remove('user');
  state.user.loggedIn = false;
}

// Mutations
function toggleSignInPopup () {
  state.status.signin.isOpen = state.status.signin.isOpen ? false : true;

  if (!state.user.loggedIn) {
    state.user = {
      phone: '',
      code: '',
      password: '',
      repeatpassword: ''
    }
  }

  state.status.signin.signup = false

  state.status.signup.code = {
    isSended: false,
    value: '',
    isValid: false  
  };
  console.log(state.status.signin.isOpen)
}

function toggleSignUp () {
  state.status.signin.signup = state.status.signin.signup ? false : true;
}


// Gettes
const getProject = computed(() => state.project)

// History
const history = []
history.push(state) 

// Watch
watch(state, (newState, oldState) => {
  history.push(newState)
})

// Module Export
export {
  state,
  login,
  sendCode,
  validCode,
  signup,
  logout,
  toggleSignInPopup,
  toggleSignUp,
}