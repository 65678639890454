const isProd = process.env.NODE_ENV === 'production';
// --------------------------------------------------
// VUE
// --------------------------------------------------
import { reactive, computed, watch  } from "vue";
// --------------------------------------------------
// GLOBALS
// --------------------------------------------------
import { setError }   from '@/store/modules/global'
// --------------------------------------------------
// AXIOS
// --------------------------------------------------
import axios from 'axios';

const clientUrl = process.env.API_URL;

const $axios = axios.create({baseURL: clientUrl})
// --------------------------------------------------
// State
// --------------------------------------------------
const state = reactive({
  all: [],

  current: {
    id: '',
    status: '',
    name: '',
    email: '',
    chat: '',
    text: ''
  },

  form: {
    status: false,
    data: {
      name: '',
      email: '',
      chat: '',
      text: ''
    }
  }
});
// --------------------------------------------------
// Async Methods
// --------------------------------------------------
async function fetchAll() {
  return await $axios.get(`/applications`).then(
    response => {
      state.all = response.data
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}

async function fetch(application) {
  return await $axios.get('/applications/' + application.id).then(
    response => {
      state.current = response.data
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}

async function create(application) {
  return await $axios.post('/applications', application).then(
    response => {
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}


async function update(application) {
  return await $axios.post('/applications/' + application.id, application).then(
    response => {
      const updatedApplication = state.all.find(a => a._id === response.data._id)
      updatedApplication = response.data
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}

async function remove(application) {
  return await $axios.delete('/applications/' + application.id).then(
    response => {
      state.all.splice(state.all.findIndex(a => a._id == application.id), 1);
      return Promise.resolve(response.data);
    },
    error => {
      setError(error)
      return Promise.reject(error);
    }
  );
}
// --------------------------------------------------
// Metgods
// --------------------------------------------------
function toggleForm() {
  state.form.status =  !state.form.status;
  state.form.data = {
    name: '',
    email: '',
    chat: '',
    text: ''
  };
}
// --------------------------------------------------
// Getters
// --------------------------------------------------
const getApplications = computed(() => state.all)
// --------------------------------------------------
// History
// --------------------------------------------------
const history = []

history.push(state)

watch(state, (newState, oldState) => { history.push(newState) })
// --------------------------------------------------
// Export module
// --------------------------------------------------
export {
  state,
  fetchAll,
  fetch,
  create,
  update,
  remove,
  toggleForm,
}