import { createSSRApp } from 'vue';
import { createAppRouter } from "./router/router.js";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import App from './App.vue';


// Global Components
import i18n from "@/localization/vue-i18n";
import * as Store from 'prometheus/store';


// Is server
export function _createApp() {
  const app = createSSRApp(App);
  const router = createAppRouter()
  const metaManager = createMetaManager(true);
  

  app
    .use(router)
    .use(i18n)


  router.beforeEach((to, from, next) => {
    const isLogin = Store.auth.state.user.loggedIn
    const currentUser = Store.auth.state.user

    const { authorize } = to.meta;

    if (authorize) {
        if (isLogin === false) {
          // not logged in so redirect to login page with the return url
          return next({ path: '/', query: { returnUrl: to.path } });
        }

        // check if route is restricted by role
        if (authorize.length && !authorize.some(r=> currentUser.roles.includes(r))) {
          // role not authorised so redirect to home page
          return next({ path: '/' });
        }
    }

    next();
  })



  return { app, router, Store, i18n  };
}
